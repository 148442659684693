// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardPatient_sectionList__2Nmrc {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 1rem;\n  gap: 1rem;\n  margin: 0.75rem 0;\n}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/DashboardPatient.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAS;EAAT,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".sectionList {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin: 0.75rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionList": "DashboardPatient_sectionList__2Nmrc"
};
export default ___CSS_LOADER_EXPORT___;
