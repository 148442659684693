// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resourcesCircle_checkboxStyle__xWrUc {\n  background-color: #fff;\n  border: 2px solid #68b3e0;\n  border-radius: 25px;\n  height: 180px;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.resourcesCircle_exitIconStyles__2qxo1 {\n  cursor: pointer;\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://src/features/circle-configurator/add-new-circle/3-resources/resourcesCircle.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".checkboxStyle {\n  background-color: #fff;\n  border: 2px solid #68b3e0;\n  border-radius: 25px;\n  height: 180px;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.exitIconStyles {\n  cursor: pointer;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxStyle": "resourcesCircle_checkboxStyle__xWrUc",
	"exitIconStyles": "resourcesCircle_exitIconStyles__2qxo1"
};
export default ___CSS_LOADER_EXPORT___;
