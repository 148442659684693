// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StringTooltip_boldText__2KY8E {\n    font-weight: bold;\n    font-family: \"Poppins\", sans-serif;\n  }\n  \n  .StringTooltip_commentText__3nxU7 {\n    margin-left: 8px;\n  }\n  \n  .StringTooltip_divider__26W0v {\n    background-color: #68b3e0;\n    margin: 8px;\n  }", "",{"version":3,"sources":["webpack://src/features/calendar/StringTooltip.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kCAAkC;EACpC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":[".boldText {\n    font-weight: bold;\n    font-family: \"Poppins\", sans-serif;\n  }\n  \n  .commentText {\n    margin-left: 8px;\n  }\n  \n  .divider {\n    background-color: #68b3e0;\n    margin: 8px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boldText": "StringTooltip_boldText__2KY8E",
	"commentText": "StringTooltip_commentText__3nxU7",
	"divider": "StringTooltip_divider__26W0v"
};
export default ___CSS_LOADER_EXPORT___;
