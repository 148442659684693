// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor_formContainer__2g8g6 {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.Editor_actionBtns__2LrIG {\n  display: flex;\n  justify-content: flex-end;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/features/share-resources/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAS;EAAT,SAAS;AACX;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAW;EAAX,WAAW;AACb","sourcesContent":[".formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.actionBtns {\n  display: flex;\n  justify-content: flex-end;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "Editor_formContainer__2g8g6",
	"actionBtns": "Editor_actionBtns__2LrIG"
};
export default ___CSS_LOADER_EXPORT___;
